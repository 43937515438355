.custom-piechart-tooltip {
  background-color: #fff;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
}

.label {
  font-weight: bold;
}

.recharts-wrapper {
  margin: 0 auto;
}
