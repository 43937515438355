.card-container {
  height: 100%;
  width: 100%;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}

.ant-table-body {
  min-height: 500px;
}

.ant-tabs-content-holder {
  display: flex;
}

.ant-tabs {
  height: 100%;
}
